<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">入住详情</div>   
        <div class="rb1" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div>  
      </div>
      <div class="content">
        <div class="items">                 
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-if="items.length !== 0">
            <!--<div class="box qq">
              <div class="name">
                <div class="headline h1 d21 cur">
                  姓名
                </div>       
                <div class="headline h1 cur" @click="checkinDateSort">入住时间</div>   
                <div class="headline h1 d22 dpx cur" @click="checkinDateSort">
                  <img src="@/assets/mrpx.png" v-if="datestate1 === '2'" class="pxicon" />
                  <img src="@/assets/zpx.png" v-if="datestate1 === '0'" class="pxicon" />
                  <img src="@/assets/dpx.png" v-if="datestate1 === '1'" class="pxicon" />                  
                </div>             
                <div class="headline h1 cur" @click="checkoutDateSort">离店时间</div>  
                <div class="headline h1 dpx cur" @click="checkoutDateSort">
                  <img src="@/assets/mrpx.png" v-if="datestate2 === '2'" class="pxicon" />
                  <img src="@/assets/zpx.png" v-if="datestate2 === '0'" class="pxicon" />
                  <img src="@/assets/dpx.png" v-if="datestate2 === '1'" class="pxicon" /> 
                </div>  
                                                                             
              </div>          
            </div>-->
            <div class="box qq">
              <div class="name">
                <div class="headline h1 d21 cur">姓名</div>       
                <div class="headline h1 d22 cur">状态</div>                               
                <div class="headline h2 dpx cur" @click="checkinDateSort">
                  <img src="@/assets/mrpx.png" v-if="datestate1 === '2'" class="pxicon" />
                  <img src="@/assets/zpx.png" v-if="datestate1 === '0'" class="pxicon" />
                  <img src="@/assets/dpx.png" v-if="datestate1 === '1'" class="pxicon" /> 
                </div> 
                <div class="headline h2 cur" @click="checkinDateSort">住宿时间</div>  
                                                                             
              </div>          
            </div>          
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <!--<div class="box y1">
              <div class="name">
                <span class="roomcode">{{ item.user_name }}</span>               
              </div>
            </div>
            <div class="box y2">
              <div class="name">                              
                <span class="floor">{{ item.checkin_date }}</span>
              </div>             
            </div>
            <div class="box y2">
              <div class="name">                              
                <span class="floor r">{{ item.checkout_date }}</span>
              </div>             
            </div>-->
            <div class="box y3">
              <div class="name">
                <span class="roomcode">{{ item.user_name }}</span>               
              </div>
            </div>
            <div class="box y4">
              <div class="name">                              
                <div class="name">                              
                  <span v-if="item.flag==='1'" class="state j">入住</span>
                  <span v-if="item.flag==='0'" class="state zt j">离开</span>              
                </div>
              </div>             
            </div>
            <div class="box y5">
              <div class="name">                              
                <span class="floor r">{{ item.checkin_date }}</span>
              </div>
              <div class="name">                              
                <span class="floor r">{{ item.checkout_date }}</span>
              </div>             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
import moment from "moment";

export default {
  created() {
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    this.session_id = this.$route.query.session_id;
    this.startdate = this.$route.query.startdate;
    this.enddate = this.$route.query.enddate;
    this.jrflag = this.$route.query.jrflag;
    this.byflag = this.$route.query.byflag;
    this.bzflag = this.$route.query.bzflag;
    this.gdflag = this.$route.query.gdflag;
    this.room_code = this.$route.query.room_code;
    
    this.query();
  },
  data() {
    return {
      type: '',
      unionid: '',
      srole: true,
      dateflag1: false,
      datestate1: '2',
      dateflag2: false,
      datestate2: '2',
      session_id: '',
      room_code: '',
      startdate: '',
      enddate: '',
      jrflag: false,
      byflag: false,   
      bzflag: false, 
      gdflag: false, 
      items: [
        /*{checkin_date: '2020-01-02 09:00:20', checkout_date: '2020-01-05 22:06:38', user_name: '王先生'},
        {checkin_date: '2020-01-02 09:00:20', checkout_date: '2020-01-05 22:06:38', user_name: '罗女士'},
        {checkin_date: '2020-01-02 09:00:20', checkout_date: '2020-01-05 22:06:38', user_name: '东方不败'}*/
      ]
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/boss/check_in_query', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole,
        startdate:this.startdate,
        enddate:this.enddate,
        jrflag:this.jrflag,
        bzflag:this.bzflag,
        byflag:this.byflag,
        gdflag:this.gdflag
      }});
    },     
    async query() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_roomxq',
        qs.stringify({
          rztimeorder: this.datestate1,
          ldtimeorder: this.datestate2,
          session_id: this.session_id,
          room_code: this.room_code
        })
      );
      if (data.flag === '0') {
        //this.items = data.data;   
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
      }
    },
    async checkinDateSort() {
      this.datestate2 ='2';
      if(this.datestate1==='0'){
        this.datestate1 ='1';
        //alert('入住日期降序');
      }else{
        this.datestate1 ='0';
        //alert('入住日期升序');
      }
      this.query();
      
    },
    async checkoutDateSort() {
      this.datestate1 ='2';
      if(this.datestate2==='0'){
        this.datestate2 ='1'
        //alert('离店日期降序');
      }else{
        this.datestate2 ='0'
        //alert('离店日期升序');
      }      
      this.query();
    }
    
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
    &.bor {
      border-bottom: 0px solid #eee;
    }
  }
  .nbox {
    flex: 1;
    overflow: hidden;
    .headline {
      font-size: 26px;
      color: rgb(95, 94, 94);   
      &.h1 {
        margin-right: 30px;
        float: left;
      }
      &.h2 {
        float: right;         
      }
      &.cc {
        color: rgb(255, 255, 255);   
      }
      &.d1 {
        margin-right: 100px;
        margin-left: 30px; 
      }
      &.d2 {
        margin-right: 30px;
      }
      &.d3 {
        margin-left: 10px; 
      }
      &.d5 {
        margin-right: 10px; 
      }
      &.d6 {
        margin-right: 0px; 
      }
      
    }
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.y1 {
      width: 20%;
    }
    &.y2 {
      width: 40%;
    }
    &.y3 {
      width: 35%;
    }
    &.y4 {
      width: 15%;
    }
    &.y5 {
      width: 50%;     
    }  
    &.zz {
      width: 80%;
    }
    &.yy {
      width: 20%;
    }
    &.yy2 {
      width: 10%;     
    }
    &.yy1 {
      width: 34%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      &.tt {
        margin-top: 32px;
      }
      &.jz {
        margin-top: 30px;
      }
      .arrow {
        float: right;
        display: block;
        margin-left: 10px;
        //margin-top: 30px;
        height: 40px;
      }
      .headline {
        font-size: 26px;       
        color: rgb(80, 80, 80);   
        &.h1 {
          margin-right: 10px;
          float: left;
        }
        &.h2 {
          float: right;         
        }
        &.cc {
          color: rgb(255, 255, 255);   
        }
        &.d1 {
          margin-right: 100px;
          margin-left: 30px; 
        }
        &.d21 {
          margin-right: 167px;
        }
        &.d22 {
          margin-right: 120px;
        }
        &.d23 {
          margin-right: 55px;
        }
        &.dpx {
          margin-top: 10px;
        }
        &.d2 {
          margin-right: 30px;
        }
        &.d3 {
          margin-left: 10px; 
        }
        &.d5 {
          margin-right: 15px; 
        }
        &.d6 {
          margin-right: 0px; 
        }
        &.d8 {
          margin-left: 18px; 
        }
        &.xz {
          color: rgb(14, 177, 206);   
        }
        &.cur {
          cursor: pointer;
        }
        &.mid {
          margin-top: 11px; 
          vertical-align: middle;
        }
        .pxicon {
          width: 26px;
          height: 26px;
          display: block;
          margin: 0px auto;          
        }
        .datespace {
          width: 330px; 
          height: 50px; 
          /* 清除默认边框 */
          border: 0;
          /* 清除默认的箭头样式 */
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-color: transparent;
          cursor: pointer;  
          &.bt {
            width: 110px; 
          }  
          &.d {
            width: 185px; 
          }         
        }
        .rb2 {
          height: 60px;
          width: 126px;
          //float: left;
          overflow: hidden;
          background: rgb(130, 189, 217);
          margin-top: 16px;
          vertical-align: middle;
          margin-left: 10px;
          border-radius: 15px;
          &.co {
            margin-top: 0px;
            background: rgb(178, 158, 214);
          }
          .icon {
            width: 30px;
            height: 30px;
            margin: 14px 5px 14px 13px;
            display: block;
            float: left;
          }        
          .text {
            float: left;
            color: #fff;
            font-size: 30px;
            line-height: 60px;
          }
        }
      }
      .imgpic {
        display: block;
        width: 114px;   
        height: 160px;  
        float: right;
      }
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .state {
        display: block;
        font-size: 25px;
        //float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        } 
        &.j {
          margin-top: 5px;
        } 
      }
      .floor {
        display: block;
        float: right;
        font-size: 25px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
        &.r {
          margin-right: 0px;
        }
      }
      .roomcode {
        //margin-right: 80px;
        font-size: 25px;
        color: rgb(168, 169, 170);
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
        &.jz {
          margin-left: 20px;
        }
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-left: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 50px;
        float: left;
        width: 260px;
        border: 0;
        color: #333;
        text-align: right;
        &.qy {
          font-size: 26px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 130px center no-repeat;
        background-size: 26px;
        width: 160px;
        height: 50px;
        background-color: transparent;
        //float: right;
        font-size: 26px;
        color:  rgb(184, 188, 190);
        line-height: 50px;
        //margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 30px;
        }
      }
      select:focus { outline: none; }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
.tj {
  width: 100%;
  height: 310px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  display: flex;
  line-height: 50px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;  
  cursor: pointer;
  &.primary {
    //background: linear-gradient(90deg, rgba(98, 226, 230, 1) 0%, rgba(18, 129, 132, 1) 100%);  
    color: rgb(0, 0, 0);
    background: rgb(246, 241, 250);
    margin-bottom: 20px;
  }
  
  &.primary0 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(240, 118, 48);
    color: #fff;
    float: right;
  }
  
  &.primary1 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(114, 191, 214);
    color: #fff;
    float: right;
  }
  &.primary2 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(226, 133, 176);
    color: #fff;
    float: right;
  }
  &.primary3 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(59, 164, 211);
    color: #fff;
    float: right;
  }
  &.primary4 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(245, 179, 74);
    color: #fff;
    float: right;
  }
  &.primary5 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);    
    background: rgb(161, 132, 207);
    color: #fff;
    float: right;
  }
  &.primary6 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);
    background: rgb(181, 204, 80);
    color: #fff;
    float: right;
  }
   &.primary7 {
    //background: linear-gradient(90deg, rgba(255, 229, 59, 1) 0%, rgba(255, 37, 37, 1) 100%);   
    background: rgb(124, 167, 223);
    color: #fff;
    float: right;
  }
  &.primary8 {
    //background: linear-gradient(90deg, rgba(149, 208, 248, 1) 0%, rgba(224, 122, 238, 1) 100%);
    background: rgb(243, 134, 139);
    color: #fff;
  }
  &.primary9 {
    //background: linear-gradient(90deg, rgba(149, 208, 248, 1) 0%, rgba(224, 122, 238, 1) 100%);
    background: rgb(100, 120, 212);
    color: #fff;
  }

  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
  &.rr {
    margin-right: 20px;
  }
  .nbx {
    flex: 1;
    overflow: hidden;
    font-size: 26px;   
    color: rgb(93, 112, 199);
    text-align: left;
    flex:0 0 auto;
    &.t {
      width: 80%;
    }
    &.w {
      width: 20%;
    }
    &.h1 {
      margin-left: 30px;
    }
    &.h2 {
      margin-left: 10px;
      //text-align: right; 
      //margin-right: 30px;        
    }    
    &.h3 {
      margin-left: 100px; 
    }
    &.d {
      margin-top: 30px; 
    }
    .nbx_1 {
      overflow: hidden;
      font-size: 26px;   
      color: rgb(182, 180, 180); 
      &.fo {
        font-size: 30px;
        color: rgb(43, 30, 87);
        margin-bottom: 10px; 
      }
      &.co {
        color:rgb(219, 55, 14); 
      }  
      .bq {
        color: rgb(139, 138, 138);   
        width: 200px;
      }
    }
  }
}

</style>
